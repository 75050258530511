var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.props.row.editFlag !== "C"
        ? _c("q-btn", {
            attrs: {
              flat: "",
              size: "12px",
              color: "orange",
              icon: "chevron_right",
            },
            on: {
              click: function ($event) {
                return _vm.rowClickDetail(_vm.props.row, _vm.props.pageIndex)
              },
            },
          })
        : _vm._e(),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      _c(
        "q-dialog",
        {
          attrs: { position: "bottom" },
          model: {
            value: _vm.dialogDetail,
            callback: function ($$v) {
              _vm.dialogDetail = $$v
            },
            expression: "dialogDetail",
          },
        },
        [
          _c(
            "q-card",
            { staticStyle: { width: "100%" } },
            [
              _c("q-linear-progress", { attrs: { value: 1, color: "pink" } }),
              _c(
                "q-card-section",
                { staticClass: "row" },
                [
                  _c("div", {
                    staticClass: "col-12 text-weight-bold-dailog",
                    domProps: { textContent: _vm._s(_vm.requestContents) },
                  }),
                  _vm._t("customArea", null, {
                    props: _vm.props,
                    col: _vm.col,
                  }),
                  _c("div", { staticClass: "col-6" }, [
                    _c("div", { staticClass: "mini-dailog-title" }, [
                      _c("i", { staticClass: "pe-7s-angle-right-circle" }),
                      _vm._v(" 개선진행 상태 "),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-6 mini-dailog-btn-impr" }, [
                    _vm.inputEditable
                      ? _c(
                          "div",
                          [
                            _c(
                              "q-btn-group",
                              { attrs: { outline: "" } },
                              [
                                _vm.inputEditable
                                  ? _c("c-btn", {
                                      attrs: {
                                        showLoading: false,
                                        label: "개선요청",
                                        color: "red-6",
                                        "text-color": "white",
                                      },
                                      on: {
                                        btnClicked: function ($event) {
                                          return _vm.innerBtnClicked(
                                            _vm.props,
                                            "1"
                                          )
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _vm.inputEditable && _vm.isImmShow
                                  ? _c("c-btn", {
                                      attrs: {
                                        showLoading: false,
                                        label: "즉시조치",
                                        color: "light-blue",
                                        "text-color": "white",
                                      },
                                      on: {
                                        btnClicked: function ($event) {
                                          return _vm.innerBtnClicked(
                                            _vm.props,
                                            "2"
                                          )
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-12 border-impr-dialog" },
                    [
                      _vm.props.row["sopImprovementIds"] &&
                      _vm.props.row["ibmStepNames"]
                        ? _vm._l(
                            _vm.$comm.ibmTagItems(_vm.props.row),
                            function (item, index) {
                              return _c(
                                "q-chip",
                                {
                                  key: index,
                                  staticStyle: {
                                    "margin-bottom": "4px !important",
                                  },
                                  attrs: {
                                    outline: "",
                                    square: "",
                                    color: item.color,
                                    clickable: true,
                                    "text-color": "white",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openImprPop(
                                        item,
                                        _vm.props.row
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.title) + " ")]
                              )
                            }
                          )
                        : [_vm._v(" 등록된 개선요청 및 즉시조치가 없습니다. ")],
                    ],
                    2
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12 mini-dailog-btn-impr" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _c("c-btn", {
                            attrs: {
                              showLoading: false,
                              label: "닫기",
                              icon: "close",
                            },
                            on: { btnClicked: _vm.dialogClose },
                          }),
                          _vm.inputEditable && _vm.acceptBtnShow
                            ? _c("c-btn", {
                                attrs: {
                                  showLoading: false,
                                  label: "적용",
                                  icon: "check",
                                  color: "amber-7",
                                },
                                on: { btnClicked: _vm.dialogSubmit },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }